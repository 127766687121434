<template>
  <div class="user-register-wrapper">
    <div class="permission-title-bar-wrap">
      <el-link target="_break" href="http://www.skysys.cn/">官方网站</el-link>
    </div>
    <form-container :logo="logo" message="欢迎注册" :has-cut-icon="false" :custom-padding="20" :title-line-margin="21">
      <el-form ref="RegisterForm" :rules="rules" :model="userInfo">
        <el-form-item label="账号" prop="userName">
          <el-input v-model="userInfo.userName" />
        </el-form-item>
        <el-form-item label="密码" prop="userPassword">
          <el-input v-model="userInfo.userPassword" type="password" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword">
          <el-input v-model="userInfo.confirmPassword" type="password" show-password />
        </el-form-item>
        <el-form-item label="手机号" prop="userMobile">
          <el-input v-model="userInfo.userMobile" />
        </el-form-item>
        <el-form-item label="验证码" prop="smsCode">
          <el-input v-model="userInfo.smsCode" placeholder="验证码">
            <template slot="append">
              <SkyAppendButton :phone="userInfo.userMobile" template-type="register" />
            </template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit('RegisterForm')"> 注 册 </el-button>
        </el-form-item>
        <el-form-item style="text-align: center; margin-bottom: 0px">
          <el-link :underline="false">
            <SkyLink path="/" text="返回登录" />
          </el-link>
        </el-form-item>
      </el-form>
    </form-container>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { userRegistered } from '@/api/user.js';
import FormContainer from '@/components/userContainer';
import statusInclude from '@/utils/statusCode';
import { validateRegisterAccount, validatePassword, validatePhone, validateVerificationCode } from '@/utils/validate.js';

export default {
  components: { FormContainer },
  data() {
    const validateConfirmPassword = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.userInfo.userPassword) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      userInfo: {
        userName: '',
        userPassword: '',
        userMobile: '',
        confirmPassword: '',
        smsCode: '',
      },
      rules: {
        userName: [{ required: true, validator: validateRegisterAccount, trigger: 'blur' }],
        userPassword: [{ required: true, validator: validatePassword, trigger: 'blur' }],
        confirmPassword: [{ required: true, validator: validateConfirmPassword, trigger: 'blur' }],
        userMobile: [{ required: true, validator: validatePhone, trigger: 'blur' }],
        smsCode: [{ required: true, validator: validateVerificationCode, trigger: 'blur' }],
      },
    };
  },
  computed: {
    ...mapGetters(['logo', 'title']),
  },
  methods: {
    // 注册
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { code, reason } = await userRegistered(this.userInfo);
          if (!statusInclude(code)) return this.$message.error(reason);
          this.$message.success(`${reason},5秒后返回到登录页`);
          setTimeout(() => {
            this.$router.push('/'); // 返回到登录页
          }, 5000);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-register-wrapper {
  width: 100%;
  height: 100%;
  background-image: url(~@/assets/images/login/content_bg.png);
  background-repeat: no-repeat;
  background-size: cover;
  /deep/ .el-form {
    .el-button {
      background: #409eff;
      span {
        color: #fff;
      }
    }
  }
}
</style>
